import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  Breadcrumbs,
  TextBox,
  QuickFacts,
  BannerLinks,
  BannerSmall,
  ProjectPagination,
  HeaderSlider,
  SplitView
} from '../../components'

import { generateBreadcrumbsLinks } from '../../utils/index'

import BannerImage from '../../assets/headerbilder.ikb.jpg'
import image4 from '../../assets/bannerlinks/bannerbox.gsk-gebro.jpg'
import image5 from '../../assets/bannerlinks/bannerbox.intercable.jpg'

import ExklusivesAngebot from '../../assets/bannerlinks/exklusivesangebot.jpg'
import CalenderImage from '../../assets/bannerlinks/calendar.jpg'

import home from '../../assets/projekte/ikb/home.png'
import contact from '../../assets/projekte/ikb/contact.png'
import internet from '../../assets/projekte/ikb/internet.png'
import internet2 from '../../assets/projekte/ikb/internet2.png'
import jobs from '../../assets/projekte/ikb/jobs.png'
import news from '../../assets/projekte/ikb/news.png'
import speed from '../../assets/projekte/ikb/speed.png'
import waerme from '../../assets/projekte/ikb/waerme.png'
import HoverButton from '../../components/HoverButton'

const slides = [
  { eachSlide: home, alt: 'IKB Website Startseite' },
  { eachSlide: contact, alt: 'IKB Website Kontakt' },
  { eachSlide: internet, alt: 'IKB Website Internettarife Vergleich' },
  { eachSlide: internet2, alt: 'Innsbrucker Kommunal Betriebe Website Internettarif' },
  { eachSlide: jobs, alt: 'IKB Karriere' },
  { eachSlide: news, alt: 'IKB Website News' },
  { eachSlide: speed, alt: 'IKB Website Fortschrittsbalken' },
  { eachSlide: waerme, alt: 'IKB Website' },
];

const boxes = [
  {
    text: 'Exklusive Angebote',
    image: ExklusivesAngebot,
    link: '/kontakt',
    linkText: 'Rückruf anfordern'
  },
  {
    text: 'Jetzt Termin vereinbaren',
    image: CalenderImage,
    link: '/kontakt',
    linkText: 'Zum Kontakt'
  }
]

const Ikb = () => {
  const links = generateBreadcrumbsLinks('/projekte/ikb', 'IKB')
  return (
    <Layout margin={false} color="white">
      <Helmet
        title="IKB.at - am Puls der Zeit"
        meta={[
          {
            name: 'description',
            content:
              'Als kommunales Infrastruktur-Dienstleistungsunternehmen dient die Webseite der IKB vor allem als Informationsportal und somit als weiterer Service für ihre Kund:innen. Bei einer Webseite mit so vielen Inhalten wie auf ikb.at steht die Skalierbarkeit aus technischer Sicht an erster Stelle.'
          },
          {
            name: 'keywords',
            content:
              'IKB holzweg, Projekt holzweg, Innsbruck, Webseite holzweg, Innsbruck Kommunalbetriebe, IKB online, Strom, Wasser, Energie, Internet'
          },
          {
            property: 'og:description',
            content:
              'Als kommunales Infrastruktur-Dienstleistungsunternehmen dient die Webseite der IKB vor allem als Informationsportal und somit als weiterer Service für ihre Kund:innen. Bei einer Webseite mit so vielen Inhalten wie auf ikb.at steht die Skalierbarkeit aus technischer Sicht an erster Stelle.'
          },
          {
            property: 'og:image',
            content: 'https://www.holzweg.com/static/bannerbox.ikb-152f58794f529d9d7b445b4cc773e75d.jpg'
          }
        ]}
      />
      <BannerSmall
        image={BannerImage}
        title="Von A wie Aufguss bis Z wie Zählerstand"
      />
      <Breadcrumbs
        links={links}
        backLinkAlias="← Übersicht aller aktuellen Projekte"
      />
      <TextBox
        title={<div>
          <span>
            IKB.at - am Puls der Zeit
          <HoverButton buttonStyle="" dataText="IKB.at" dataTitle="Jump In!" link={"https://www.ikb.at"} />
          </span>
        </div>}
        text={
          <div>
            <p>
              Wir freuen uns, Ihnen unsere erfolgreiche Zusammenarbeit mit den Innsbrucker Kommunalbetrieben AG (IKB) bei der Entwicklung einer modernen und informativen Unternehmenswebsite vorzustellen. Das Ziel dieses Projekts war es, eine erstklassige digitale Präsenz für IKB zu schaffen, die als effektive digitale Visitenkarte des Unternehmens fungiert.
            </p>
            <p>
              Bei der Gestaltung der Website haben wir großen Wert darauf gelegt, umfassende Informationen über die vielfältigen Produkte und Dienstleistungen von IKB bereitzustellen. Von Wasser- und Abwasserversorgung über Energieversorgung bis hin zur Abfallentsorgung – die Besucher der Website können sich über das gesamte Leistungsspektrum von IKB informieren.
            </p>
            <p>
              Ein weiterer wichtiger Aspekt bei der Entwicklung der Website war die Spiegelung des Corporate Designs der Innsbrucker Kommunalbetriebe. Wir haben eng mit dem Team von IKB zusammengearbeitet, um sicherzustellen, dass das Erscheinungsbild der Website die Markenidentität von IKB widerspiegelt. Farben, Schriftarten und grafische Elemente wurden sorgfältig ausgewählt, um ein einheitliches und professionelles Erscheinungsbild zu gewährleisten.
            </p>
            <p>
              Wir sind stolz darauf, Teil des Projekts zur Entwicklung der Unternehmenswebsite für die Innsbrucker Kommunalbetriebe AG zu sein. Die Zusammenarbeit mit dem engagierten Team von IKB war äußerst produktiv und effektiv. Das positive Feedback von IKB und die hohe Zufriedenheit der Website-Besucher bestätigen den Erfolg des Projekts. Wir freuen uns darauf, auch zukünftig eng mit IKB zusammenzuarbeiten und ihnen bei ihren digitalen Anforderungen behilflich zu sein.
            </p>
            <p style={{ fontWeight: 'bold' }}>
              Überzeugen Sie sich selbst: <a href='https://www.ikb.at' target='_blank'>www.ikb.at</a>
            </p>
          </div>
        }
        buttonText="Zur Webseite"
        buttonLink="https://www.ikb.at"
      />
      <QuickFacts
        facts={[
          { count: 3, suffix: '', text: 'Portale in einem' },
          { count: 50, suffix: '%', text: 'mobile User' },
          { count: 100, suffix: '%', text: 'modernes User Interface' }
        ]}
        color="red"
      />
      <SplitView
        displayImageLeft={false}
        title="Breites Leistungsspektrum"
        text={
          <>
            <br />
            <p>
            Die Benutzer:innen unserer Website können sich ausführlich über das breite Spektrum der Produkte und Dienstleistungen von IKB informieren, angefangen von der zuverlässigen Wasser- und Abwasserversorgung über die zuverlässige Energieversorgung bis hin zur effizienten Abfallentsorgung. Unsere klare Gestaltung und benutzerfreundliche Navigation ermöglichen es den Besuchern, problemlos die gewünschten Informationen zu finden und einen umfassenden Einblick in die Leistungsfähigkeit von IKB zu erhalten.
            </p>
            <br />
            {/* <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum.
            </p> */}
          </>
        }
        linkText="Mehr Eindrücke entdecken"
        link='#slider'
        backgroundImage={internet2}
      />
      <SplitView
        displayImageLeft={true}
        title="Corporate Design"
        text={
          <>
            <br />
            <p>
              Ein weiterer wichtiger Aspekt bei der Entwicklung der Website war die Spiegelung des Corporate Designs der Innsbrucker Kommunalbetriebe. Wir haben eng mit dem Team von IKB zusammengearbeitet, um sicherzustellen, dass das Erscheinungsbild der Website die Markenidentität von IKB widerspiegelt. Farben, Schriftarten und grafische Elemente wurden sorgfältig ausgewählt, um ein einheitliches und professionelles Erscheinungsbild zu gewährleisten.
            </p>
            <br />
            <p>
            Wir sind stolz darauf, Teil des Projekts zur Entwicklung der Unternehmenswebsite für die Innsbrucker Kommunalbetriebe AG zu sein. Die Zusammenarbeit mit dem engagierten Team von IKB war äußerst produktiv und effektiv. Das positive Feedback von IKB und die hohe Zufriedenheit der Website-Besucher bestätigen den Erfolg des Projekts. Wir freuen uns darauf, auch zukünftig eng mit IKB zusammenzuarbeiten und ihnen bei ihren digitalen Anforderungen behilflich zu sein.
            </p>
          </>
        }
        linkText="Website erkunden"
        link='https://www.ikb.at'
        backgroundImage={home}
      />

      <HeaderSlider slides={slides} />
      <TextBox titleMods={['center']} title="Weitere Best-Performer" text="" />
      <ProjectPagination
        titleLeft="Haleon-Gebro Multiportal"
        imageLeft={image4}
        leftLink="/projekte/haleon-gebro"
        titleRight={
          <span>
            Intercable Multiportal
            <br /> und Shop
          </span>
        }
        imageRight={image5}
        rightLink="/projekte/intercable"
        titleModsRight={['black']}
      />
      <TextBox
        title="Walk with us on the holzweg"
        text={
          <span>
            <p>Gemeinsam mit unserer Internetagentur betreten Sie einen spannenden Weg voller Potenzial. Unsere maßgeschneiderten Lösungen und Expertise in der Suchmaschinenoptimierung eröffnen Ihnen neue digitale Möglichkeiten. Ob Sie Ihr Online-Business mit einem sonnigen Spaziergang starten oder mit einem kraftvollen Lauf voranbringen möchten, wir sind hier, um Sie zu unterstützen.</p>
            <p>Unsere erfahrenen Experten stehen an Ihrer Seite, um Ihre Online-Präsenz zu stärken, damit Sie in den Suchergebnissen ganz vorne stehen. Gemeinsam beschreiten wir diesen Weg und bringen Ihre Webseite zum Strahlen. Vereinbaren Sie noch heute einen Termin und entdecken Sie mit unserer Internetagentur das Potenzial Ihrer Projekte! Wir freuen uns darauf, Ihre digitale Reise zum Erfolg zu begleiten.</p>
          </span>
        }
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}

export default Ikb